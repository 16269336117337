<template>
  <div class="video">
    <div class="left">
      <div class="border-wrap">
        <div class="border-top flex">
          <div class="border-top-left">
            <img src="../assets/border/border-left-top.png" />
          </div>
          <div class="border-top grow">
            <img src="../assets/border/border-top.png" />
          </div>
          <div class="border-title">
            <div class="border-name">设备列表</div>
          </div>
          <div class="border-top grow">
            <img src="../assets/border/border-top.png" />
          </div>
          <div class="border-top-right">
            <img src="../assets/border/border-right-top.png" />
          </div>
        </div>
        <div class="border-body row-height-1 flex">
          <div class="border-left">
            <img src="../assets/border/border-left.png" />
          </div>
          <div class="border-body-middle grow">
            <!--  -->
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in deviceList"
                :key="index"
                :class="[currentDeviceIndex == index ? 'active' : '']"
                @click="toggleCurrentDeviceIndex(index)"
                @dblclick="setPlayer"
              >
                {{ item.videoName }}
              </div>
            </div>
            <!--  -->
          </div>
          <div class="border-right">
            <img src="../assets/border/border-right.png" />
          </div>
        </div>
        <div class="border-bottom flex">
          <div class="border-bottom-left">
            <img src="../assets/border/border-left-bottom.png" />
          </div>
          <div class="border-bottom grow">
            <img src="../assets/border/border-bottom.png" />
          </div>
          <div class="border-bottom-middle">
            <img src="../assets/border/border-middle-bottom.png" />
          </div>
          <div class="border-bottom grow">
            <img src="../assets/border/border-bottom.png" />
          </div>
          <div class="border-bottom-right">
            <img src="../assets/border/border-right-bottom.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="border-wrap">
        <div class="border-top flex">
          <div class="border-top-left">
            <img src="../assets/border/border-left-top.png" />
          </div>
          <div class="border-top grow">
            <img src="../assets/border/border-top.png" />
          </div>
          <div class="border-title">
            <div class="border-name">
              <span>实时视频</span>
            </div>
          </div>
          <div class="border-top grow">
            <img src="../assets/border/border-top.png" />
          </div>
          <div class="border-top-right">
            <img src="../assets/border/border-right-top.png" />
          </div>
        </div>
        <div class="border-body row-height-1 flex">
          <div class="border-left">
            <img src="../assets/border/border-left.png" />
          </div>
          <div class="border-body-middle grow">
            <!--  -->
            <div class="wrap">
              <div class="player">
                <div
                  class="item"
                  v-for="(item, index) in 4"
                  :key="index"
                  :class="[
                    multiScreen ? 'multiScreen' : '',
                    currentPlayerIndex == index ? 'active' : '',
                  ]"
                  v-show="index == 0 || multiScreen"
                  @click="toggleCurrentPlayerIndex(index)"
                  @dblclick="fullScreenCurrentPlayer"
                  :id="'player' + index"
                >
                  <div class="mask"></div>
                </div>
              </div>
              <div class="buttons">
                <div class="row">
                  <img
                    src="../assets/camControler/top.png"
                    class="direction"
                    @mousedown="onStartControl(0)"
                    @mouseup="onStopControl(0)"
                  />
                </div>
                <div class="row">
                  <img
                    src="../assets/camControler/left.png"
                    class="direction"
                    @mousedown="onStartControl(2)"
                    @mouseup="onStopControl(2)"
                  />
                  <img
                    src="../assets/camControler/lock.png"
                    class="lock"
                    @mousedown="onStartControl(0)"
                    @mouseup="onStopControl(0)"
                  />
                  <img
                    src="../assets/camControler/right.png"
                    class="direction"
                    @mousedown="onStartControl(3)"
                    @mouseup="onStopControl(3)"
                  />
                </div>
                <div class="row">
                  <img
                    src="../assets/camControler/bottom.png"
                    class="direction"
                    @mousedown="onStartControl(1)"
                    @mouseup="onStopControl(1)"
                  />
                </div>
                <div class="row m2">
                  <img
                    src="../assets/camControler/zoomIn.png"
                    class="control"
                    @mousedown="onStartControl(8)"
                    @mouseup="onStopControl(8)"
                  />
                  <img
                    src="../assets/camControler/zoomOut.png"
                    class="control"
                    @mousedown="onStartControl(9)"
                    @mouseup="onStopControl(9)"
                  />
                </div>
                <div class="row m2">
                  <img
                    src="../assets/camControler/capture.png"
                    class="control"
                    @click="playerCapturePicture"
                  />
                  <img
                    src="../assets/camControler/stop.png"
                    class="control"
                    @click="stopCurrentPlayer"
                  />
                </div>
                <div class="row m2">
                  <img
                    src="../assets/camControler/fullScreen.png"
                    class="control"
                    @click="fullScreenCurrentPlayer"
                  />
                  <img
                    v-if="multiScreen"
                    src="../assets/camControler/multi.png"
                    class="control"
                    @click="toggleMultiScreen"
                  />
                  <img
                    v-if="!multiScreen"
                    src="../assets/camControler/multi2.png"
                    class="control"
                    @click="toggleMultiScreen"
                  />
                </div>
              </div>
            </div>
            <!--  -->
          </div>
          <div class="border-right">
            <img src="../assets/border/border-right.png" />
          </div>
        </div>
        <div class="border-bottom flex">
          <div class="border-bottom-left">
            <img src="../assets/border/border-left-bottom.png" />
          </div>
          <div class="border-bottom grow">
            <img src="../assets/border/border-bottom.png" />
          </div>
          <div class="border-bottom-middle">
            <img src="../assets/border/border-middle-bottom.png" />
          </div>
          <div class="border-bottom grow">
            <img src="../assets/border/border-bottom.png" />
          </div>
          <div class="border-bottom-right">
            <img src="../assets/border/border-right-bottom.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@Width: 192rem;
.row-height-1 {
  height: 765 / @Width;
}

.video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .left {
    width: 390 / @Width;
    height: 100%;
    .list {
      width: 100%;
      height: 100%;
      overflow: scroll;
      .item {
        height: 60 / @Width;
        line-height: 60 / @Width;
        text-align: center;
        margin-bottom: 20 / @Width;
        background: linear-gradient(#1b41be, #140a5e);
        border: 1px solid #0079fe;
        color: #00e4ff;
        font-size: 16 / @Width;
      }
      .item:first-child {
        margin-top: 10 / @Width;
      }
      .active {
        box-shadow: 0 0 5 / @Width #fff;
        color: #fff;
      }
    }
  }
  .right {
    width: 1430 / @Width;
    height: 100%;
    .wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .player {
        width: 1150 / @Width;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        .item {
          width: 1150 / @Width;
          height: 648 / @Width;
          background-color: black;
          border: 1px solid #666;
          box-sizing: border-box;
          overflow: hidden;
          position: relative;
          .mask {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        .multiScreen {
          width: 1150 / 2 / @Width;
          height: 648 / 2 / @Width;
        }
        .active {
          border: 1px solid #fff;
        }
      }
      .buttons {
        width: 210 / @Width;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .row {
          display: flex;
          justify-content: space-around;
          align-items: center;
          margin: 8 / @Width 0;
          .direction {
            width: 40 / @Width;
            height: 40 / @Width;
          }
          .locak {
            width: 70 / @Width;
            height: 70 / @Width;
          }
          .control {
            width: 90 / @Width;
            height: 90 / @Width;
          }
        }
        .m2 {
          margin: 20 / @Width 0;
        }
      }
    }
  }
}

.border-wrap {
  .flex {
    display: flex;
  }
  .grow {
    flex-grow: 1;
  }
  .border-top {
    .border-top-left,
    .border-top-right {
      width: 50 / @Width;
      height: 50 / @Width;
      img {
        width: 50 / @Width;
        height: 50 / @Width;
      }
    }
    .border-top {
      height: 50 / @Width;
      img {
        width: 100%;
        height: 50 / @Width;
      }
    }
    .border-title {
      width: 205 / @Width;
      height: 50 / @Width;
      background-image: url("../assets/border/border-title.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
      .border-name {
        color: #00e4ff;
        font-size: 18 / @Width;
        font-weight: 700;
        text-align: center;
        margin-top: 8 / @Width;
        span {
          display: inline-block;
          width: 150 / @Width;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .border-body {
    .border-left,
    .border-right {
      width: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 100%;
      }
    }
    .border-body-middle {
      background-image: url("../assets/border/border-body-bg.png");
      filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
  }

  .border-bottom {
    .border-bottom-left,
    .border-bottom-right {
      width: 25 / @Width;
      height: 25 / @Width;
      img {
        width: 25 / @Width;
        height: 25 / @Width;
      }
    }
    .border-bottom {
      height: 25 / @Width;
      img {
        width: 100%;
        height: 25 / @Width;
      }
    }
    .border-bottom-middle {
      width: 258 / @Width;
      height: 25 / @Width;
      img {
        width: 258 / @Width;
        height: 25 / @Width;
      }
    }
  }
}
</style>

<script>
export default {
  name: "Video",
  data() {
    return {
      deviceList: [],
      currentDeviceIndex: 0,
      multiScreen: false,
      currentPlayerIndex: 0,
      playerList: [
        { player: null, data: null },
        { player: null, data: null },
        { player: null, data: null },
        { player: null, data: null },
      ],
    };
  },
  computed: {
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    currentDevice: function () {
      return this.deviceList[this.currentDeviceIndex];
    },
  },
  watch: {
    currentProject(newVal, oldVal) {
      console.log("watch currentProject=>", newVal);
      this.onGetDeviceList(); // 获取视频设备列表
    },
    multiScreen(newVal, oldVal) {
      if (!newVal) {
        this.currentPlayerIndex = 0;
        this.closeMultiScreen(); // 关闭多屏模式冗余其余通道
      }
    },
    playerList: {
      handler: function (newVal, oldVal) {
        console.log("watch playerList=>", newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.onGetDeviceList(); // 获取视频设备列表
  },
  methods: {
    // 获取设备列表
    onGetDeviceList: function () {
      if (!this.currentProject.id) {
        return;
      }
      var that = this;
      var data = {
        projectId: this.currentProject.id,
      };
      this.$axiosAdmin({
        method: "post",
        url: `api/screen/ys/videoListByPid`,
        data: JSON.stringify(data),
      })
        .then((res) => {
          that.deviceList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换当前选中设备
    toggleCurrentDeviceIndex: function (index) {
      this.currentDeviceIndex = index;
    },
    // 切换当前选中播放器
    toggleCurrentPlayerIndex: function (index) {
      this.currentPlayerIndex = index;
    },
    // 切换多屏模式
    toggleMultiScreen: function () {
      this.multiScreen = !this.multiScreen;
    },
    // 设置视频播放
    setPlayer: function () {
      // if (!this.playerList[this.currentPlayerIndex].player) {
      //   var params = {
      //     id: "player"+this.currentPlayerIndex, // 视频容器ID
      //     accessToken: this.currentDevice.token,
      //     url: this.currentDevice.ezopenUrl,
      //   }
      //   this.playerList[this.currentPlayerIndex].player = new EZUIKit.EZUIKitPlayer(params);
      // } else {
      //   this.playerList[this.currentPlayerIndex].player.stop()
      //   this.playerList[this.currentPlayerIndex].player.params = {
      //     id: "player"+this.currentPlayerIndex,
      //     accessToken: this.currentDevice.token,
      //     url: this.currentDevice.ezopenUrl,
      //   }
      //   this.playerList[this.currentPlayerIndex].player.opt.accessToken = this.currentDevice.token
      //   this.playerList[this.currentPlayerIndex].player.opt.url = this.currentDevice.ezopenUrl
      //   this.playerList[this.currentPlayerIndex].player.play()
      // }
      this.stopCurrentPlayer();
      var params = {
        id: "player" + this.currentPlayerIndex, // 视频容器ID
        accessToken: this.currentDevice.token,
        url: this.currentDevice.ezopenUrl,
      };
      this.playerList[this.currentPlayerIndex].player =
        new EZUIKit.EZUIKitPlayer(params);
    },
    // 关闭当前视频通道
    stopCurrentPlayer: function () {
      if (this.playerList[this.currentPlayerIndex].player) {
        this.playerList[this.currentPlayerIndex].player.stop();
        var playerDom = document.getElementById(
          "EZUIKitPlayer-player" + this.currentPlayerIndex
        );
        playerDom.remove();
        this.playerList[this.currentPlayerIndex].player = null;
        this.playerList[this.currentPlayerIndex].data = null;
      }
    },
    // 截图当前视频通道
    playerCapturePicture: function () {
      if (this.playerList[this.currentPlayerIndex].player) {
        this.playerList[this.currentPlayerIndex].player.capturePicture();
      }
    },
    // 全屏当前视频通道
    fullScreenCurrentPlayer: function () {
      if (this.playerList[this.currentPlayerIndex].player) {
        this.playerList[this.currentPlayerIndex].player.fullScreen();
      }
    },
    // 关闭多屏模式冗余通道
    closeMultiScreen: function () {
      this.playerList.forEach((item, index) => {
        if (index != 0) {
          console.log("closeMultiScreen=>", index);
          if (item.player) {
            item.player.stop();
            var playerDom = document.getElementById(
              "EZUIKitPlayer-player" + index
            );
            playerDom.remove();
            item.player = null;
            item.data = null;
          }
        }
      });
    },
    // 停止云台控制
    onStopControl: function (direction) {
      if (!this.playerList[this.currentPlayerIndex].player) return;
      var accessToken =
        this.playerList[this.currentPlayerIndex].player.opt.accessToken;
      var deviceSerial =
        this.playerList[this.currentPlayerIndex].player.opt.deviceSerial;
      var channelNo =
        this.playerList[this.currentPlayerIndex].player.opt.channelNo;
      this.stopControl(accessToken, deviceSerial, channelNo, direction);
    },
    // 开始云台控制
    onStartControl: function (direction) {
      if (!this.playerList[this.currentPlayerIndex].player) return;
      var accessToken =
        this.playerList[this.currentPlayerIndex].player.opt.accessToken;
      var deviceSerial =
        this.playerList[this.currentPlayerIndex].player.opt.deviceSerial;
      var channelNo =
        this.playerList[this.currentPlayerIndex].player.opt.channelNo;
      this.startControl(accessToken, deviceSerial, channelNo, direction);
    },
    // 停止云台控制
    // https://open.ys7.com/api/lapp/device/ptz/stop
    stopControl: function (accessToken, deviceSerial, channelNo, direction) {
      this.$axios({
        method: "post",
        url: "https://open.ys7.com/api/lapp/device/ptz/stop",
        data: this.$qs.stringify({
          accessToken,
          deviceSerial,
          channelNo,
          direction,
        }),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 开始云台控制
    // https://open.ys7.com/api/lapp/device/ptz/start
    startControl: function (accessToken, deviceSerial, channelNo, direction) {
      this.$axios({
        method: "post",
        url: "https://open.ys7.com/api/lapp/device/ptz/start",
        data: this.$qs.stringify({
          accessToken,
          deviceSerial,
          channelNo,
          direction,
          speed: 1,
        }),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>